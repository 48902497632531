import { Skeleton } from '@mui/material'
import React, { ImgHTMLAttributes, useCallback, useEffect, useState } from 'react'

export const useLazyImage = (defUrl?: string) => {
  const [src, setSrc] = useState<string>('')

  const loader = useCallback((urlSet: string, width?: number, height?: number) => {
    return new Promise((resolve, reject) => {
      if (!urlSet) return reject('error url.')
      const image = new Image(width, height)
      image.srcset = urlSet
      image.src = urlSet
      image.onload = () => {
        // Visual Effects
        setTimeout(() => {
          setSrc(urlSet)
          resolve(urlSet)
        }, 200)
      }
      image.onerror = () => {
        setSrc(urlSet)
        reject(`loader image error: ${urlSet}`)
      }
    })
  }, [])

  useEffect(() => {
    if (defUrl) {
      loader(defUrl)
    }
  }, [defUrl, loader])

  return {
    src,
    loader,
  }
}
export type ILazyImageProps = {
  src: string
} & ImgHTMLAttributes<HTMLImageElement>

const LazyImage: React.FC<ILazyImageProps> = ({ src, ...rest }) => {
  const { width = '100%', height = '100%' } = rest
  const { src: imageSrc, loader } = useLazyImage(src)
  useEffect(() => {
    if (src) {
      loader(src)
    }
  }, [src, loader])

  if (!imageSrc) {
    return <Skeleton animation="wave" variant="rectangular" sx={{ width, height }} />
  }

  return (
    <picture>
      <img src={imageSrc} alt="image" {...rest} />
    </picture>
  )
}

export default LazyImage
