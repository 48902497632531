import React, { useMemo, useState } from 'react'
import {
  AppBar,
  Box,
  ButtonBase,
  Drawer,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { useAccount } from 'wagmi'
import { useWeb3Modal } from '@web3modal/react'
import MenuIcon from '@mui/icons-material/Menu'
import { useSelector } from 'react-redux'
import { ReactComponent as FlowerSVG } from '@/assets/imgs/account/flower.svg'
import { ReactComponent as CanSVG } from '@/assets/imgs/home/can.svg'
import { RootState } from '@/store'

const Account = dynamic(() => import('./Account'), { ssr: false })
export const Logo = (props: TypographyProps) => {
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Link href="/">
      <Typography
        variant="emilysCandy"
        fontSize={isMD ? 32 : 20}
        {...props}
        sx={{
          background: 'linear-gradient(to bottom, var(--ps-primary-100), var(--ps-primary))',
          backgroundClip: 'text',
          textFillColor: 'transparent',
        }}
      >
        Beeverse
      </Typography>
    </Link>
  )
}

const Menus = () => {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const router = useRouter()
  const { address } = useAccount()
  const { open: web3Open } = useWeb3Modal()
  const isMD = useMediaQuery(theme.breakpoints.up('md'))
  const [open, setOpen] = useState(false)
  const { userBalance: balanceData } = useSelector((state: RootState) => state.user)
  const links = useMemo(
    () => [
      {
        label: t('header.menus.blind'),
        src: '/blind',
      },
      {
        label: t('header.menus.honey'),
        src: '/refine',
      },
      {
        label: t('header.menus.shop'),
        src: '/shop',
      },
    ],
    [t],
  )

  const MenuLink = (link: any) => {
    return (
      <Box
        onClick={() => {
          if (address) {
            router.push(link.src)
          } else {
            web3Open()
          }
        }}
        key={link.label}
        sx={{ cursor: 'pointer' }}
      >
        <Typography
          suppressHydrationWarning
          variant="bodyLarge"
          color={router.route === link.src ? 'var(--ps-white)' : 'var(--ps-gray-400)'}
          sx={{ '&:hover': { color: 'var(--ps-white)' } }}
        >
          {link.label}
        </Typography>
      </Box>
    )
  }

  if (isMD) {
    return (
      <Stack component="nav" direction="row" spacing={40} sx={{ pl: isMD ? 100 : 30, flexGrow: 1 }}>
        {links.map((link) => MenuLink(link))}
      </Stack>
    )
  }

  return (
    <Box sx={{ px: 8 }}>
      <ButtonBase
        onClick={() => {
          setOpen(true)
        }}
      >
        <MenuIcon sx={{ fontSize: 30 }} />
      </ButtonBase>
      <Drawer
        anchor="right"
        open={open}
        sx={{ '> .MuiDrawer-paper': { width: 240 } }}
        onClose={() => {
          setOpen(false)
        }}
      >
        <Stack spacing={32} sx={{ p: 20 }}>
          <Box sx={{ span: { fontSize: 32 } }}>
            <Logo />
          </Box>
          <Stack spacing={12} sx={{}}>
            {links.map((link) => MenuLink(link))}

            <Box display={'flex'} alignItems={'center'}>
              <FlowerSVG width={24} /> pollen:{balanceData?.pollenTotal || 0}
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <CanSVG width={24} />
              honey:{balanceData?.honeyTotal || 0}
            </Box>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  )
}

const Languages = () => {
  const router = useRouter()
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.up('md'))
  const handleChangeLge = (lang: string) => {
    router.push(router.pathname, router.asPath, {
      locale: lang,
    })
  }
  return (
    <Select
      variant="standard"
      value={router.locale}
      onChange={(ev) => {
        handleChangeLge(ev.target.value)
      }}
      sx={{
        fontSize: !isMD && 12,
        height: !isMD && 30,
      }}
    >
      <MenuItem value="en-US">English</MenuItem>
      <MenuItem value="zh-CN">简体中文</MenuItem>
      <MenuItem value="zh-TW">繁體中文</MenuItem>
    </Select>
  )
}

export const Header: React.FC = () => {
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <AppBar
      sx={{
        height: 86,
        boxShadow: 'none',
        justifyContent: 'center',
        px: isMD ? 30 : 10,
        background: 'linear-gradient(to bottom, black, transparent)',
      }}
    >
      <Toolbar sx={{ justifyContent: { xs: 'space-between' } }}>
        <Logo />
        <Stack
          direction={{ xs: 'row-reverse', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          sx={{ flex: { md: 1 } }}
        >
          <Menus />
          <Stack direction="row" spacing={isMD ? 24 : 10} alignItems="center">
            <Languages />
            <Account />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
