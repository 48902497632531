import React from 'react'
import { Box, Container, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import styled from '@emotion/styled'
import Link from 'next/link'
import LazyImage from '../LazyImage'
import { ReactComponent as FacebookSVG } from './assets/facebook.svg'
import { ReactComponent as InsSVG } from './assets/ins.svg'
import { ReactComponent as TwitterSVG } from './assets/twitter.svg'

const NavTitle = styled(Typography)`
  color: var(--ps-primary);
  font-size: 16px;
  text-transform: uppercase;
`

const NavLink = styled(Typography)`
  font-size: 16px;
  color: var(--ps-gray-400);

  &:hover {
    color: var(--ps-white);
  }
`

export const Footer = () => {
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box sx={{ background: 'url(/imgs/footer_bg.webp) center' }}>
      <Container maxWidth="xl" sx={{ px: 30, height: isMD ? 465 : 'auto', pt: isMD ? 135 : 100 }} disableGutters>
        <Stack direction={isMD ? 'row' : 'column'} spacing={!isMD && 20} justifyContent="space-between">
          <Stack spacing={isMD ? 20 : 10} sx={{ width: isMD ? 230 : '100%' }}>
            <Stack
              direction={'row'}
              spacing={isMD ? 10 : 5}
              alignItems={'center'}
              sx={{ '>picture': { height: 'auto', width: 'auto' } }}
            >
              <LazyImage src="/logo.png" height={!isMD && 45} />
              <Typography
                suppressHydrationWarning
                variant="emilysCandy"
                fontSize={isMD ? 48 : 30}
                color="var(--ps-primary)"
              >
                Beeverse
              </Typography>
            </Stack>

            <Typography fontSize={!isMD && 12}>
              The World’s First and largest Digital Marketplace for Crypto Collectibles and Non - Fungible Tokens
            </Typography>
            <Stack direction="row" spacing={16}>
              <IconButton size="small">
                <InsSVG />
              </IconButton>
              <IconButton size="small">
                <FacebookSVG />
              </IconButton>
              <IconButton size="small">
                <TwitterSVG />
              </IconButton>
            </Stack>
          </Stack>
          <Stack
            component="ul"
            direction={'row'}
            spacing={isMD ? 112 : 14}
            sx={{ li: { listStyle: 'none' }, pl: !isMD && 0 }}
          >
            <li>
              <NavTitle fontSize={!isMD && 12}>marketpace</NavTitle>
              <Stack sx={{ mt: isMD ? 36 : 20 }} spacing={isMD ? 20 : 4} component="nav">
                <Link href={''}>
                  <NavLink fontSize={!isMD && 12}>Whitepaper</NavLink>
                </Link>
                <Link href={''}>
                  <NavLink fontSize={!isMD && 12}>Docs</NavLink>
                </Link>
              </Stack>
            </li>
            <li>
              <NavTitle fontSize={!isMD && 12}>COMPANY</NavTitle>
              <Stack sx={{ mt: isMD ? 36 : 20 }} spacing={isMD ? 20 : 4} component="nav">
                <Link href={'/'}>
                  <NavLink fontSize={!isMD && 12}>Home</NavLink>
                </Link>
                <Link href={''}>
                  <NavLink fontSize={!isMD && 12}>Explorer</NavLink>
                </Link>
                <Link href={''}>
                  <NavLink fontSize={!isMD && 12}>Faucet</NavLink>
                </Link>
              </Stack>
            </li>
            <li>
              <NavTitle fontSize={!isMD && 12}>RESOURCES</NavTitle>
              <Stack sx={{ mt: isMD ? 36 : 20 }} spacing={isMD ? 20 : 4} component="nav">
                <Link href={'/'}>
                  <NavLink fontSize={!isMD && 12}>Telegram</NavLink>
                </Link>
                <Link href={''}>
                  <NavLink fontSize={!isMD && 12}>Discord</NavLink>
                </Link>
                <Link href={''}>
                  <NavLink fontSize={!isMD && 12}>Medium</NavLink>
                </Link>
              </Stack>
            </li>
          </Stack>
        </Stack>
        <Box sx={{ pt: 24, mt: 50, pb: 12, borderTop: '1px solid rgba(255,255,255,0.1)' }}>
          <Typography suppressHydrationWarning variant="bodyLarge" color="var(--ps-gray-400)">
            @2022 Beeverse. All rights reserved
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}
