import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Footer } from './Footer'
import { Header } from './Header'

export type IPageLayoutProps = {
  children: React.ReactNode
  disableGutters?: boolean
  clientRender?: boolean
}

const PageLayout: React.FC<IPageLayoutProps> = ({ disableGutters, children, clientRender }) => {
  const gutter = disableGutters ? 0 : 86
  const [load, setLoad] = useState(false)
  useEffect(() => {
    setLoad(true)
  }, [])

  if (clientRender && !load) {
    return null
  }

  return (
    <main>
      <Header />
      <Stack sx={{ pt: gutter, minHeight: `calc(100vh - ${gutter}px)` }}>{children}</Stack>
      <Footer />
    </main>
  )
}

export default PageLayout
